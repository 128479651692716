import styled from 'styled-components';

import { MODAL_Z_INDEX } from '../common/styles/zIndexes';

export const ModalContainer = styled.div`
  z-index: ${MODAL_Z_INDEX};
  position: fixed;
  top: 0;
  left: 0;
`;

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
`;

import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import * as S from './styles';

const GoodbyeShine = React.lazy(() =>
  import(/* webpackChunkName: "GoodbyeShine" */ './GoodbyeShine'),
);

const Home = React.lazy(() => import(/* webpackChunkName: "Home" */ './Home'));

function App() {
  return (
    <S.Root>
      <Suspense>
        <Routes>
          <Route element={<Home />} path="/" />
          <Route element={<GoodbyeShine />} path="/goodbye-shine" />
        </Routes>
      </Suspense>
      <S.ModalContainer id="modal" />
    </S.Root>
  );
}

export default App;
